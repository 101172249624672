import React from "react"

import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Content, { HTMLContent } from "../components/Content"
import Layout, { Section } from "../components/Layout"

export const GenericPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return <PageContent className={["content markdown"].join(" ")} content={content} />
}

const EmptyPage = ({ data }) => {
  const post = data.markdownRemark

  const helmet = (
    <Helmet titleTemplate="%s | Post">
      <title>{`${post.frontmatter.title}`}</title>
      <meta name="description" content={`${post.frontmatter.description}`} />
    </Helmet>
  )

  return (
    <Layout>
      <Section helmet={helmet}>
        <GenericPageTemplate
          contentComponent={HTMLContent}
          title={post.frontmatter.title}
          content={post.html}
        />
      </Section>
    </Layout>
  )
}

export default EmptyPage

export const query = graphql`
  query EmptyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
